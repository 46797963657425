import React from "react";
import { Container } from "react-bootstrap";

export default function ArticleItem({ data }) {
  return (
    <Container style={{ marginTop: "7%" }}>
      <div
        dangerouslySetInnerHTML={{
          __html: data.content,
        }}
      ></div>
    </Container>
  );
}
